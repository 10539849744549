import React, { useEffect } from 'react';

import LandingPage from '../components/LandingPage/LandingPage';

import LogRocket from 'logrocket';
LogRocket.init('qbfdxd/histartup', {
  dom: {
    inputSanitizer: true,
  },
});

const Home = () => {

  useEffect(() => {
    console.log(window.gtag)
  }, [])

  return (
    <LandingPage 
      pageType="founder"
      seo="Home"
      image={require("../images/meta-preview.jpg")}
      headerButtonLabel="Investors"
      headerButtonLink="/investors"
      heroTitle="Get your startup seen by Angel Investors"
      heroCtaLabel="Create your profile"
      heroCtaLink="/"
      heroImg={require("../images/startup-preview-1.jpg")}
      SectionOneImg={require("../images/conversation.png")}
      SectionOneTitle="Get the conversation started"
      SectionOneBody="After an investor declares an interest in your idea, you can easily respond to any questions they may have about your idea. Share files and links to relevant documents and seal the deal without leaving the platform."
      SectionTwoImg={require("../images/prompt-questions.png")}
      SectionTwoTitle="Build your profile quickly with prompt questions"
      SectionTwoBody="Communicate your idea with clarity by responding to our library of prompt questions, carefully selected based on the information that investors want to hear. Answer with text or let your personality flow via audio and video."
      endTitle="Get your startup seen by Angel Investors"
      endCtaLabel="Create your profile"
      endCtaLink="/"
    />
  )
}

export default Home;

